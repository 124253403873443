html {
  height: 100%;
  overflow-y:scroll;
}

nav, footer, body{
  background-color: #000;
  color: #fff;
  padding: 0;
}

img{
  width:100%;
}

ul {
  List-style-type: none;
  padding-left: 0.5rem;
}

a{
  text-decoration: none;
}

nav a{
  color:white;
  font-weight:500;
}

nav a.active{
  color:#6ff18b;
}

a:hover{
  color: #c7fa94;
}

a:focus {
  outline: 0;
}

.center_text{
  text-align: center;
}

.page_title{
  font-family: Arial, Helvetica, sans-serif;
  color: #37ffff;
  text-shadow: 2px 1px 1px rgb(187, 59, 176);
  border-bottom: 1px solid rgb(117, 223, 255);
  font-weight: bold;
  font-style: italic;
  display: block;
  margin: auto;
}

.alignContentMiddle{
  vertical-align: top;
}

body{
  background-color: rgb(31, 31, 31);
  position: relative;
  margin: 0;
  padding-bottom: 4rem;
  min-height: 100%;
}

.NoWrap{
  display: inline;
  white-space: nowrap;
}

/*************/
/**** NAV ****/
/*************/
nav h2{
  font-family: monospace;
}

nav h5{
  font-style: italic;
  color: #dfdfdf;
  font-weight: 400;
  padding: 0;
}

nav ul div{
  float: right;
}

nav li{
  display: inline;
  white-space: nowrap;
}

.nav_link{
  padding-right: 50px;
}

.last_nav_link{
  padding-right: 50px;
}

/****************/
/**** FOOTER ****/
/****************/
footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

footer a{
  color:rgb(189, 236, 241);
}

.MailMe:hover{
  color: #c01269;
}

.LinkedIn:hover{
  color: #0a66c2;
}

.DeviantArt:hover{
  color: #00e59b;
}

.Facebook:hover{
  color: #1877f2;
}

.Github:hover{
  color: #ffffff;
}

.Itch_io:hover{
  color: #ff244a;
}

/***************/
/**** INDEX ****/
/***************/
.ImgIndex{
  height: 250px;
}

.latest_content{
  overflow: overlay;
  max-height: 240px;
}

.latest_content a{
  color:rgb(51, 132, 224)
}

.latest_content a:hover{
  color:rgb(9, 113, 173)
}

.gradient_img{
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,1) 15%, rgba(0,0,0,0));
  position: absolute;
  right: 0;
}


/******************/
/**** PROJECTS ****/
/******************/
.project_box_gradient{
  box-shadow: 25px 25px 35px 0 rgb(31, 31, 31) inset, -25px -25px 35px 0 rgb(31, 31, 31) inset; 
}

/***************/
/**** ABOUT ****/
/***************/
.achieved_skill{
  color: rgb(255, 255, 255);
}
.no_skill{
  color: gray;
}

.skill_holder_1 i{
  width: 20px
}
.skill_holder_2 i{
  width: 25px
}

.cv_title{
  font-size: 1.6em;
}

.blue_title{
  color: lightblue;
}


/*****************/
/**** CONTACT ****/
/*****************/

.contact_text{
  min-height: 170px;
} 

.required:after{
  content: "*";
  color: red;
  padding-left: 0.2em;
}

button:hover{
  background-color: #c7e6f3;
}

/****************/
/**** @MEDIA ****/
/****************/
@media screen and (max-width: 767px){
  nav h2{
    font-size: 1.3rem;
  }

  nav h5{
    font-size: 1.0rem;
  }
}

@media screen and (max-width: 575px){
  .last_nav_link{
    padding-right: 0px;
  }
}

@media screen and (max-width: 465px){
  .nav_link{
    padding-right: 20px;
    font-size: small;
  }
  .last_nav_link{
    font-size: small;
  }
}

@media screen and (max-width: 424px){
  nav h2{
    font-size: 1.7rem;
  }

  nav h5{
    font-size: 1.1rem;
  }
}
